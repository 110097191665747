.Right{
    width: 100%;
    color: black;
    padding-top: 8em;
}
@media(max-width:700px){
	.Right{
        padding-top: 7em;
	}
}
.heading{
    text-decoration: underline;
}