.wrapper .multi_color_border{
    width: 100%;
    height: 5px;
    background: linear-gradient(to right, #ffb80c 0% ,#ffb80c  25%, #494949 25%, #494949 50%, #ffb80c 50%, #ffb80c
     75%, #494949 75%, #494949 100%);
}
.wrapper{
    left: 0px;
	top: 0px;
	position: fixed;
	height: 18%;
	width: 100%;
	color: #fff;
	z-index: 3;
}
.wrapper .top_nav{
    height: 65px;
    background: #fff;
    padding: 0 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right{
    font-size: 1.1rem;
    font-weight: bold;
    color: #575555;
}

@media(max-width:600px){
    .right {
        font-size: 1rem;   
    }
}
@media(max-width:700px){
    .wrapper .top_nav{
      padding: 0 10px;
    }
    .wrapper .top_nav .right p{
        font-size: 0.9rem;   
    }
    .wrapper .top_nav .left .logo p{
        font-size: 1.1rem;
    }
}
.wrapper .bottom_nav{
    width: 100%;
}
  
.wrapper .bottom_nav ul{
    background: #0f2327;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    align-items: center;
    list-style-type: none;
    margin: 0;
    overflow-x: scroll;
	overflow-y: hidden;
	height: 40px;
  	white-space:nowrap
}
.wrapper .bottom_nav ul li a{
    color: #eeeeee;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 11px;
    margin: 0 6px;
    text-decoration: none;
}
 @media(min-width:480px){
  .wrapper .bottom_nav ul{
    overflow: hidden;
  }
}
/*
@media(max-width:300px){
    .wrapper .bottom_nav ul{
      grid-template-columns: auto auto 50%;
    }
  } */

.wrapper .bottom_nav ul li a:hover,
.wrapper .bottom_nav ul li a:active,
.wrapper .bottom_nav ul li a.active{
    color: #ffb80c;
}

.nav-pills > li > a {
    border-radius: 0;
}