::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    box-sizing: border-box;
    margin: 2px 0;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
}
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.7); 
}
.fancyScroll::-webkit-scrollbar {
    width: 5px;
}
.fancyScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
}
.fancyScroll::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3);
    border-radius: 10px;
}
.fancyScroll::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.7); 
}
.LeftSection{
    position: fixed;
    width: 28%;
    height: 100%;
    background-color: #fff;
    overflow-y: scroll;
    z-index: 2;
}
.Heading{
    background-color: #222831;
    color:orange;
    text-align:center;
    font-size:1.1rem;
    font-weight: 700;
}
.grid{
    display: grid;
    grid-template-columns: 80% 20%;
    text-align: left;
    padding: 0 0.5rem;
}

.tableBody tr :hover{
    background-color: rgb(238, 185, 115);
}
@media(max-width:700px){
    .LeftSection{
        display: none;
    }
}