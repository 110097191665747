.nav{
    display:grid;
    grid-template-columns: 26% 14% 14% 56%;
    align-items:center;
    margin: 10px;
}
@media(max-width:1000px){
    .nav{
        grid-template-columns: 60% 25% 15%;
    }
}
.Right{
    width: 100%;
    color: black;
    padding-top: 8em;
}
@media(max-width:700px){
	.Right{
        padding-top: 7em;
	}
}