.root{
    width: 100%;
    padding-top: 8em;
}
.changepass{
    padding-top:80px;
    background-color: white;
    height:350px;
    margin: 0px 10px ;
}
.helper{
    color: #1e1e1e;
    font-weight: 900;
    padding: 10px 10px;
    margin: 0px 10px ;
    text-decoration: underline;
    font-size: 1.25em;
}
.table{
    width: 100%;
    display: flex;
    justify-content: center;
}
.label{
    display: inline-block;
    width: 200px;
    font-weight: bold;
}
.p{
    color: red;
    text-align: right;
}
.p::before{
    display: inline;
}
.materialUIInput {
    display: inline-block;
    /* box-sizing: border-box; */
    width: 50%;
    border-radius: 4px;
    background-color: white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}
.button{
    display: flex;
    justify-content: center;
}
.input[type="submit"] {
    background: #1976d2;
    border-radius: 10%;
    color: white;
    border: none;
    padding: 15px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}